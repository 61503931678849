const queueDefinition = {
  "active-cases-queue": {
    name: "active-cases-queue",
    label: "active-cases-label",
    queueFilter: "?status=active&hasIncidents=false",
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  },
  "failed-cases-queue": {
    name: "failed-cases-queue",
    queueFilter: "?status=active&hasIncidents=true",
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 5
  }
}

export { queueDefinition }
