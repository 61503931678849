import React from "react";
import ReactDOM from "react-dom";

import { CaseManager, APPS, FEATURES } from "@flow/case-manager";

import * as serviceWorker from "./serviceWorker";
import "./index.css";

import { queueDefinition } from "./configuration/queues";
import { caseConfig } from "./configuration/case";
import customTranslations from "./translations";

const FlowPortalWithApps = (
  <CaseManager
    configuration={() => {
      return {
        apps: [APPS.Inbox, APPS.Search, APPS.Process],
        inboxConfiguration: {
          startableProcesses: ["application"],
          features: [FEATURES.CaseAssignment]
        },
        searchConfiguration: {
          searchQueryView: "searchView",
          searchFields: [
            { name: "Customer", value: "data.customer.name", queryOperator: "contains" },
            { name: "Foobar", value: "data.foobar", queryOperator: "equals" }
          ],
          features: [FEATURES.CaseAssignment]
        },
        customComponents: {
          queueDefinition,
          case: caseConfig
        },
        language: "en",
        translates: [
          {
            language: "no",
            translates: customTranslations.no
          },
          {
            language: "en",
            translates: customTranslations.en
          }
        ]
      }
    }
  }/>
);

ReactDOM.render(FlowPortalWithApps, document.getElementById("root"));

serviceWorker.unregister();
